import React from 'react';
import './Section6.css';
import SectionTitle from '../../components/Atoms/SectionTitle';

export default function Section6()
{
    const items = [
        <p>Înscriere</p>,
        <p>Curs de legislație</p>,
        <p>Examinare teoretică la școală  <br></br>- IEȘIRE DIN ȘCOALĂ (chestionar cu 26 de întrebări)</p>,
        <p>15 ședințe de conducere</p>,
        <p>Examen teoretic D.G.P.C.I (sală)</p>,
        <p>Examen practic D.G.P.C.I (traseu)</p>,
    ]
    
    return(
        <div className='section6-container' id="etape">
            <SectionTitle title="Etapele școlarizării" side={true}/>
            <div className='section6-container-core'>
                <div className='section6-container-core-left'>
                {
                    items.map((el)=>{
                        return(
                            <div className='section6-container-core-left-row'>
                                <img src="/media/icons/stepper.png"/>
                                <p>{el}</p>
                            </div>
                        )
                    })
                }
                </div>
                <div className='section6-container-core-right'>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Candidaţii declaraţi «respins» la proba teoretică pot susţine o nouă examinare, cu achitarea taxei corespunzătoare, numai după o perioadă de cel puţin 15 zile de la data declarării ca «respins».</p>
                    <p className='section6-container-core-right-special'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Candidaţii declaraţi «respins» la proba practică pot fi programaţi pentru susţinerea unei noi probe după o perioadă de cel puţin 15 zile, cu achitarea taxei de examinare şi prezentarea dovezii care atestă efectuarea a cel puţin 6 ore suplimentare de pregătire practică într-o şcoală de conducători auto autorizată.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color: "var(--main)", fontWeight: 'bold'}}>Cursanții se vor adresa secretariatului școlii cu dosarul personal, unde se va întocmi un contract suplimentar de școlarizare și vor achita contravaloarea orelor.</span> După efectuarea celor 6 ore minime obligatorii, li se vor elibera <span style={{color: "var(--main)", fontWeight: 'bold'}}>adeverința</span> pentru o nouă programare la examen.</p>
                </div>
            </div>
        </div>
    )
}